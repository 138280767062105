@use "styles" as *;

.wrapperText {
  margin-top: 28px;
  @include sm-desktop {
    margin-top: 24px;
  }
}

.bigTitle {
  @include text-xxl-extralight;
  strong {
    @include text-xxl-semibold;
  }

  @include sm-desktop {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.bigText {
  @include text-m-regular;
  &:not(:first-child) {
    margin-top: 16px;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @include sm-desktop {
    &:not(:first-child) {
      margin-top: 24x;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.paragraph {
  @include text-m-regular;
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.wrapperLink {
  &:not(:first-child) {
    margin-top: 20px;
  }
}
